import styled from 'styled-components';

import { Box, Text } from 'grommet';

const StyledMeetingsContainer = styled(Box)`
  width: 100%;
  min-height: 25rem;
  justify-content: center;
`;

const StyledFormContainer = styled(Box)`
  width: 100%;
  padding: 1.5rem;
  background-color: white;
  border-radius: 0.5rem;
  min-height: 25rem;
  justify-content: center;
`;

const StyledHeading = styled(Text)`
  line-height: 1.2;
`;

const StyledList = styled.ol`
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 20px;

  li {
    font-size: ${({ size }) => size};
    color: ${({ color }) => color};
  }
`;

export {
  StyledMeetingsContainer, StyledFormContainer, StyledHeading, StyledList,
};
